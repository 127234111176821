<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header color="foreground">
        <v-row justify="center">
          <v-col align-self="center" cols="auto">
            <v-icon>mdi-filter</v-icon>
          </v-col>
          <v-col align-self="center" cols="auto"> Filter Clinics</v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content color="foreground">
        <v-row justfiy="center">
          <v-col cols="12">
            <mex-sheet color="foreground" elevation="0">
              <v-row justify="center">
                <v-col align-self="center" class="pr-0" cols="auto">
                  <v-autocomplete v-model="clinicNameFilter" :items="clinicNames" dense hide-details label="Clinic Name" outlined>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="clinicNameFilter = null" />
                </v-col>
                <v-col class="pr-0" cols="auto">
                  <v-autocomplete v-model="registerTypeNameFilter" :items="registerTypeNames" dense hide-details label="Register-Type" outlined>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                        <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="registerTypeNameFilter = null" />
                </v-col>
                <v-col class="pr-0" cols="auto">
                  <v-autocomplete v-model="organizationNameFilter" :items="organizationNames" dense hide-details label="Organization" outlined>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="organizationNameFilter = null" />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col align-self="center" class="pr-0" cols="auto">
                  <v-select v-model="clinicStateIdx" :items="clinicStates" dense hide-details label="State" outlined>
                    <!-- <mex-select label="State" :items="clinicStates" @change="clinicStateChanged" :selection="clinicStateIdx" outlined> -->
                    <template v-slot:selection="data">
                      <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                      {{ data.item.text }}
                    </template>
                    <template v-slot:item="data">
                      <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                      {{ data.item.text }}
                    </template>
                  </v-select>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="clinicStateIdx = 0" />
                </v-col>
                <v-col align-self="center" class="pr-0" cols="auto">
                  <v-select v-model="deactivationStateIdx" :items="deactivationStates" dense hide-details label="Deactivation" outlined>
                    <template v-slot:selection="data">
                      <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                      {{ data.item.text }}
                    </template>
                    <template v-slot:item="data">
                      <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                      {{ data.item.text }}
                    </template>
                  </v-select>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="deactivationStateIdx = 0" />
                </v-col>
                <v-col align-self="center" class="pr-0" cols="auto">
                  <v-select v-model="systemUserNameFilter" :items="systemUserNames" dense hide-details label="System-Users" multiple outlined>
                    <template v-slot:selection="data">
                      <v-chip class="ma-1">
                        <v-icon class="mr-1" x-small>mdi-account</v-icon>
                        {{ data.item.text }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="systemUserNameFilter = null" />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col align-self="center" class="pr-0" cols="auto">
                  <v-select v-model="meditexBackupStateIdx" :items="meditexBackupStates" dense hide-details label="MedITEX Backup" outlined>
                    <template v-slot:selection="data">
                      <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                      {{ data.item.text }}
                    </template>
                    <template v-slot:item="data">
                      <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                      {{ data.item.text }}
                    </template>
                  </v-select>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="meditexBackupStateIdx = 0" />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="auto">
                  <mex-btn content="Set filter" icon="mdi-filter" @click="buildFilterData" />
                </v-col>
              </v-row>
            </mex-sheet>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ClinicsService from '../../services/clinics.service';
import OrganizationsService from '../../services/organizations.service';
import RegisterTypesService from '../../services/registerTypes.service';
import SystemUsersService from '../../services/systemUsers.service';

export default {
  name: 'LicSrvClinicOverviewFilter',
  data() {
    return {
      filterData: {
        clinicName: null,
        organizationName: null,
        registerTypeName: null,
        state: null,
        deactivation: null,
        systemUserIDs: null,
        meditexBackupState: null
      },
      clinicNames: [],
      clinicNameFilter: '',
      organizationNames: [],
      organizationNameFilter: '',
      registerTypeNames: [],
      registerTypeNameFilter: '',
      clinicStates: [
        {
          text: null,
          icon: '',
        },
        {
          text: 'Reported',
          icon: 'mdi-circle',
          iconColor: 'success',
        },
        {
          text: 'Overdue 24h',
          icon: 'mdi-circle',
          iconColor: 'warning',
        },
        {
          text: 'Overdue 48h',
          icon: 'mdi-circle',
          iconColor: 'error',
        },
      ],
      clinicStateIdx: 0,
      meditexBackupStates: [
        {
          text: null,
          icon: '',
        },
        {
          text: 'Reported',
          icon: 'mdi-check-circle',
          iconColor: 'success',
        },
        {
          text: 'Overdue',
          icon: 'mdi-close-circle',
          iconColor: 'error',
        },
        {
          text: 'Not checked',
          icon: 'mdi-pause-circle',
          iconColor: 'gray',
        },
      ],
      meditexBackupStateIdx: 0,
      deactivationStates: [
        {
          text: null,
          icon: '',
        },
        {
          text: 'Deactivated',
          icon: 'mdi-lock-alert',
          iconColor: 'error',
        },
        {
          text: 'Deactivation planed',
          icon: 'mdi-lock-open-alert',
          iconColor: 'warning',
        },
        {
          text: 'Not deactivated',
          icon: 'mdi-lock-open-check',
          iconColor: 'success',
        },
      ],
      deactivationStateIdx: 0,
      systemUserNames: [],
      systemUserNameFilter: [],
    };
  },
  methods: {
    fetchFilterData() {
      ClinicsService.getClinicNames().then((clinicResponse) => {
        clinicResponse.data.forEach((clinic) => {
          this.clinicNames.push({
            value: clinic.ClinicID,
            text: clinic.name,
          });
        });
        OrganizationsService.getOrganizationNames().then((organizationResponse) => {
          organizationResponse.data.forEach((orga) => {
            this.organizationNames.push({
              value: orga.name,
              text: orga.name,
            });
          });
          RegisterTypesService.getRegisterTypeNames().then((registerTypeResponse) => {
            registerTypeResponse.data.forEach((registerType) => {
              this.registerTypeNames.push({
                value: registerType.acronym,
                text: registerType.acronym,
                description: registerType.name,
              });
            });
            SystemUsersService.getSystemUserNames().then((systemUserResponse) => {
              systemUserResponse.data.forEach((systemUser) => {
                this.systemUserNames.push({
                  value: systemUser.SystemUserID,
                  text: systemUser.username,
                });
              });
            });
          });
        });
      });
    },
    buildFilterData() {
      this.filterData.clinicName = this.clinicNameFilter;
      this.filterData.organizationName = this.organizationNameFilter;
      this.filterData.registerTypeName = this.registerTypeNameFilter;
      this.filterData.systemUserIDs = this.systemUserNameFilter;
      this.filterData.state = this.calcStateDate();
      this.filterData.meditexBackupState = this.meditexBackupStateIdx;
      this.$emit('filterUpdate', this.filterData);
    },
    calcStateDate() {
      if (this.clinicStateIdx === this.clinicStates[1].text) {
        return new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
      }
      if (this.clinicStateIdx === this.clinicStates[2].text) {
        return new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();
      }
      if (this.clinicStateIdx === this.clinicStates[3].text) {
        return new Date(new Date().setDate(new Date().getDate() - 2)).toISOString();
      }
      return null;
    },
  },
  created() {
    this.fetchFilterData();
  },
};
</script>

<style></style>
