<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Clinics Overview" />
      </v-col>
    </v-row>
    <mex-sperm-spinner v-if="clinicOverviewLoading" spinnerText="loading clinic overview" />
    <template v-else>
      <v-row>
        <v-col cols="12">
          <clinic-overview-filter @filterUpdate="triggerFilterUpdate" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <mex-card cardClass="foreground" icon="mdi-hospital-building" title="Clinics" width="100%">
            <clinics-table :filterData="filterData" />
          </mex-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ClinicOverviewFilter from '../../components/LicSrvComponents/ClinicOverviewFilter.vue';
import ClinicsTable from '../../components/LicSrvComponents/ClinicsTable.vue';
import requiredPermissions from '../../requiredPermissions';

export default {
  name: 'ClinicsOverview',
  components: { ClinicOverviewFilter, ClinicsTable },
  computed: {
    ...mapGetters('sysAuth', ['isLoggedIn', 'getUser', 'getUserPermissions']),
  },
  data() {
    return {
      // Visualization
      clinicOverviewLoading: false,
      hideRegCompleteColumn: true,
      // table
      filterData: null,
      hasPermission: false,
    };
  },
  methods: {
    triggerFilterUpdate(value) {
      this.filterData = value;
    },
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.clinicAdministration, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
};
</script>

<style></style>
